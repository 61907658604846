import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import { UserManagementModal } from "../modals/UserManagementModal";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import { sweetalert } from '../../App';
import { getPageOptions, slicePages, hideModal, showModalNoOutsideClick } from "../../util/FormatUtil";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";

import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import UsersAPI from "../../network/UsersAPI";
import PaginationTool from "../PaginationTool";

import { BiMailSend } from "react-icons/bi";
import { FaUserPlus } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { getIdToken, signOut } from "firebase/auth";
import AdminAPI from "../../network/AdminAPI";
import { UserContext } from "../../types/UserContext";

import { auth } from "../../FirebaseConfig";

interface PermState {
  showLoading;
  tableResults: {
    UUID;
    DisplayName;
    Email;
    Permissions: [];
    Status;
    AuthFacilityGroup;
  }[];
  selectedUser: {
    UUID;
    DisplayName;
    Email;
    Permissions: [];
    FacilityIDs: [];
    Status;
    FirstName;
    LastName;
    AuthFacilityGroup;
    DefaultPassword
    PhoneNumber
  };
  filter?: {
    // FirstName;
    // LastName;
    ID;
    Email;
    FacilityGroups;
    Permissions;
    Status;
  };

  page_options: ReactSelect[];
  selected_page?;
  totalUsers?;

  queryResults: {
    UUID
    DisplayName;
    Email;
    Permissions;
    FacilityIDs;
    Status;
    FirstName;
    LastName;
    CustomerName;
    AuthFacilityGroup;
    PhoneNumber;
  }[];
  permGroups: { label, value }[]; // Permission Groups from IdP
  facilities: { label, value}[];
  facilitiesWithInfo
  customerGroups: { label, value, facilityGroupID }[]; // Facility and Consent Entity Groups from Idp

  direction?: TableOrder;

  allUsers;
}

interface PermProps { }

export class UserManagement extends React.Component<PermProps, PermState> {

  static contextType = AuthContext;
  static UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      queryResults: [] as any,
      tableResults: [] as any,
      selectedUser: { permissions: [] } as any,
      totalUsers: 0,
      page_options: [{ value: "1", label: "1" }],
      selected_page: { label: 1, value: 1 },
      permGroups: [{ label: "", value: -1 }] as any,
      facilities: [{ label: "", value: -1}] as any,
      customerGroups: [{ label: "", value: -1, facilityGroupID: -1 }] as any,
      filter: {
        // FirstName: "",
        // LastName: "",
        ID: "",
        Email: "",
        FacilityGroups: [],
        Permissions: [],
        Status: "",
      },
      direction: "asc",
      allUsers: [],
      facilitiesWithInfo: []
    };
    this.filterUsers = this.filterUsers.bind(this);
    this.disableUserAccount = this.disableUserAccount.bind(this);
    this.adjustPermissions = this.adjustPermissions.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  changePage(page: number) {
    let allUsers = this.state.queryResults;
    let returnData = slicePages(allUsers, page, 50);
    this.setState({ tableResults: returnData });
  }

  filterUsers(page: number) {
    this.setState(
      {
        showLoading: true,
        selectedUser: {
          UUID: -1,
          DisplayName: "",
          FirstName: "",
          LastName: "",
          Email: "",
          Permissions: [],
          FacilityIDs: [],
          Status: "Enabled",
          AuthFacilityGroup: null,
          DefaultPassword: "",
          PhoneNumber: null
        },
      },
      async () => {
        let fields = {
          ID: this.state.filter.ID,
          Email: this.state.filter.Email,
          FacilityGroups: this.state.filter.FacilityGroups,
          Permissions: this.state.filter.Permissions,
          Status: this.state.filter.Status
        };

        let response = await UsersAPI.filterUsers({ filter: fields });
        if (!response.success || (response.users && !response.users.length)) {
          this.setState({
            showLoading: false,
            tableResults: slicePages(response.users, page, 50),
            queryResults: response.users,
            totalUsers: response.users.length,
            page_options: getPageOptions(response.num_pages),
          });
          return sweetalert.fire({
            icon: "info",
            title: "",
            text: "No results were returned",
          });
        }

        this.setState({
          showLoading: false,
          tableResults: slicePages(response.users, page, 50),
          queryResults: response.users,
          totalUsers: response.users.length,
          page_options: getPageOptions(response.num_pages),
        });
      }
    );
  }

  componentDidMount() {
    document.title = 'User Management Page';
    this.setState({ showLoading: true }, async () => {
      // AuthContext User is our Firebase user, if we are logged in we can get our
      // UserRoles from the user returned with getAuthenticated() 
      try{
        let idToken = await getIdToken(this.context,true)
        const userCredential = await AdminAPI.getAuthenticated({token:idToken})
        let userProfile:UserContext = userCredential.user;
        UserManagement.UserContext =userProfile;
      } catch(e){
        console.error(e)
      }
      // use promises instead of await for permissions and groups
      UsersAPI.getValidGroups().then((response) => {
        if (!response.success) {
          sweetalert.fire({ icon: "error", title: "", text: response.reason });
        }
        let permissionGroups = response.groups.map((g) => ({
          label: g.description,
          value: g.id,
        }));
        // permissionGroups.push({ label: "null", value: "" });
        this.setState({ permGroups: permissionGroups });
      });
      UsersAPI.getCustomerGroups().then((response) => {
        if (!response.success) {
          sweetalert.fire({ icon: "error", title: "", text: response.reason });
        }
        let validGroups = response.customerGroups;
        this.setState({ customerGroups: validGroups })
      });
      UsersAPI.getUsersForUserMgmt().then(data => {
        this.setState({allUsers: data.users})
      })
      AdminAPI.getFacilitiesForSubmissionForm().then((data) => {
        let facs = data.authorizedFacilities;
        let facilityNamesMapped = facs.map((obj) => {
          return { label: obj.FacilityName, value: obj.ID };
        })
        this.setState({
          facilitiesWithInfo: facs,
          facilities: facilityNamesMapped.sort(),
          showLoading: false,
        });
      });
    });
  }

  createUserAccount = async (user: {
    FirstName;
    LastName;
    Email;
    SelectedPerms;
    SelectedFacilities;
    Status
    Disable;
    UUID;
    AuthFacilityGroup: { label; value }
    PhoneNumber
    FacilityIDs
  }) => {

    let userData = {
      FirstName: user.FirstName,
      LastName: user.LastName,
      Email: user.Email,
      Status: user.Status,
      AuthFacilityGroup: user.AuthFacilityGroup.value,
      RoleIds: user.SelectedPerms.map((p) => p.value),
      FacilityIDs: user.SelectedFacilities.map(f => f.value),
      UUID: user.UUID, // should be -1 since we are creating user
      PhoneNumber: user.PhoneNumber
    };

    let response = await UsersAPI.createUserAccount(userData);
    if (!response.success) {
      this.setState({ showLoading: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: response.reason,
      });
    }
    let newUser = response.user;
    let displayMessage = "<p>User account created for " + newUser.Email +
      " with a default password. An email has been sent to the user with this password." +
      " They will be prompted to reset it when they log in.</p>"

    this.filterUsers(this.state.selected_page.value);
    return sweetalert.fire({
      icon: "success",
      title: "",
      html: displayMessage,
    });
  };

  disableUserAccount = async (selectedUser: {
    FirstName?: any;
    LastName?: any;
    Email?: any;
    SelectedPerms?: any;
    Status?: any;
    Disable?: any;
    FacilityGroup?: any;
    FacilityIDs?: any;
    UUID?: any;
    DisplayName?: any;
    PhoneNumber: any
  }) => {
    let id = selectedUser.UUID;
    let isDisablingOwnAccount = id === UserManagement.UserContext.UUID;
    let userName = isDisablingOwnAccount ? "your own": selectedUser.DisplayName+"'s";
    let message = "Are you sure you want to disable " + userName + " account?"
    let additionalMessage = "\n\nThis will force a logout and you will not be able to log back in."
    let warningText = isDisablingOwnAccount ? message + additionalMessage : message;
    return sweetalert
      .fire({
        title: "Attention",
        text: warningText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Disable",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let response = await UsersAPI.disableUserAccount(id);

          if (!response.success) {
            this.setState({ showLoading: false });
            return sweetalert.fire({
              icon: "error",
              title: "",
              text: response.reason,
            }).then(()=>{
              if(isDisablingOwnAccount){
                signOut(auth).then(()=>{
                  window['location'] = '/login' as unknown as Location;
                })
              }
              else {
                this.filterUsers(this.state.selected_page.value);
              }
            });
          } else {
            return sweetalert.fire({
              icon: "success",
              title: "",
              text: "User account disabled.",
            }).then(()=>{

              if(isDisablingOwnAccount){
                signOut(auth).then(()=>{
                  window['location'] = '/login' as unknown as Location;
                })
              }
              else {
                this.filterUsers(this.state.selected_page.value);
              }
            });
          }

        }
        if (result.isDismissed) {
          this.setState({ showLoading: false });
        }
      });
  };

  adjustPermissions = async (permList: any[]) => {
    let userid = this.state.selectedUser.UUID;
    let permissions = permList.map((p) => p.value);
    let response = await UsersAPI.adjustUserPermissions(userid, permissions);
    return response;
  };

  updateUserProfileInfo = async (user: { UUID; FirstName; LastName; PhoneNumber; }) => {
    let response = await UsersAPI.updateUserProfileInfo(user);
    return response;
  }

  submitUserData(user: {
    FirstName;
    LastName;
    Email;
    SelectedPerms;
    SelectedFacilities;
    Status;
    Disable;
    UUID;
    AuthFacilityGroup;
    PhoneNumber
    FacilityIDs
  }) {
    let isNewUser = user.UUID === -1;
    let shouldBeDisabled = user.Disable;
    let updatedPermissions = user.SelectedPerms;

    this.setState({ showLoading: true }, async () => {
      try {
        if (isNewUser) { await this.createUserAccount(user) }
        else if (shouldBeDisabled) { 
          await this.disableUserAccount(user) 
        }
        else {
          let permissionResponse = await this.adjustPermissions(updatedPermissions)
          if(!permissionResponse.success){
            return sweetalert.fire("Error updating Permissions: " + permissionResponse.reason)
          }
          let updateResponse = await this.updateUserProfileInfo({ UUID: user.UUID, FirstName: user.FirstName, LastName: user.LastName, PhoneNumber: user.PhoneNumber})
          if(!updateResponse.success){
            return sweetalert.fire("Error updating User Profile: " + updateResponse.reason)
          }
        }

        this.setState({
          showLoading: false,
          selectedUser: {
            UUID: -1,
            DisplayName: "",
            FirstName: "",
            LastName: "",
            Email: "",
            Permissions: [],
            FacilityIDs: [],
            Status: "Enabled",
            AuthFacilityGroup: null,
            DefaultPassword: "",
            PhoneNumber: null
          }
        }, () => {
          this.filterUsers(this.state.selected_page.value)
          // return sweetalert.fire({ title: "", icon: 'success', text: "User updated." })
        })
      } catch (e) {
        console.error(e)
        sweetalert.fire({ icon: 'error', title: "", text: e })
      }
    });
  }

  renderFilterCardFields() {
    return (
      <FilterCard
        fields={[
          {
            label: "User",
            key: "ID",
            type: FIELD_TYPE.SELECT,
            options: this.state.allUsers.map(u => {return {label: u.DisplayName, value: u.ID}}),
            isMapped: true,
            isClearable: true,
            textType: "text",
            isFilter: true
          },
          // {
          //   label: "First Name",
          //   key: "FirstName",
          //   type: FIELD_TYPE.TEXT,
          // },
          // {
          //   label: "Last Name",
          //   key: "LastName",
          //   type: FIELD_TYPE.TEXT,
          // },
          {
            label: "Email",
            key: "Email",
            type: FIELD_TYPE.TEXT,
            isFilter: true
          },
          {
            label: "Permissions",
            key: "Permissions",
            type: FIELD_TYPE.SELECT,
            options: this.state.permGroups,
            isMapped: true,
            isMulti: true,
            textType: "text",
            isFilter: true
          },
          {
            label: "Facility Group",
            key: "FacilityGroups",
            type: FIELD_TYPE.SELECT,
            options: this.state.customerGroups,
            isMapped: true,
            isMulti: true,
            textType: "text",
            isFilter: true
          },
          {
            label: "Status",
            key: "Status",
            type: FIELD_TYPE.SELECT,
            options: [
              "Enabled",
              "Disabled"
            ],
            isMulti: true,
            textType: "text",
            isFilter: true
          },
        ]}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV("/api/admin/user/csvExport", "Users.xlsx", {
        users: this.state.queryResults
      });
      this.setState({ showLoading: false });
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>()
      .withRawSorterFunc("Permissions", (a, b) => {
        let aPermissions = a.Permissions[0]["desc"];
        let bPermissions = b.Permissions[0]["desc"];
        return aPermissions.localeCompare(bPermissions);
      })
      .withRawSorterFunc("AuthFacilityGroup", (a, b) => {
        let aGroup: { label; value } = this.state.customerGroups.find(
          (af) => af.value === a.facilityGroupID
        );
        let bGroup: { label; value } = this.state.customerGroups.find(
          (af) => af.value === b.facilityGroupID
        );
        if (!aGroup) return -1;
        if (!bGroup) return 1;
        return aGroup && bGroup ? aGroup.label.localeCompare(bGroup.label) : 0;
      });
    this.setState({
      tableResults: sorter.sortByKey(
        this.state.tableResults,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  enableUserAccount = async (selectedUser) => {
    let id = selectedUser.UUID;
    let response = await UsersAPI.enableUserAccount(id);

    if (!response.success) {
      this.setState({ showLoading: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: response.reason,
      });
    }

    this.filterUsers(this.state.selected_page.value);
    return sweetalert.fire({
      icon: "success",
      title: "",
      text: "User account enabled.",
    });
  };

  resetPasswordEmail = async (selectedUser) => {
    let response = await UsersAPI.sendResetPasswordEmail(selectedUser,null, null);
    if (!response.success) {
      this.setState({ showLoading: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: response.reason,
      });
    }
    this.filterUsers(this.state.selected_page.value);
    return sweetalert.fire({
      icon: "success",
      title: "",
      text: "Password Reset Email sent.",
    });
  };

  render() {
    // console.log('UserMgmt state', this.state)
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <UserManagementModal
          selectedUser={this.state.selectedUser}
          facilityGroups={this.state.customerGroups}
          permissionGroups={this.state.permGroups}
          facilities={this.state.facilities}
          facilitiesWithInfo={this.state.facilitiesWithInfo}
          onSubmit={(user) => {
            hideModal(UserManagementModal.ID);
            this.submitUserData(user);
          }}
        />
        <div className="container-fluid  min-vh-100 ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="User Management">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h4>User Management</h4>
                  </div>
                  <div className="card-body">{this.renderFilterCardFields()}</div>
                  <div className="card-footer">
                    <button
                      className="btn immySubmitButtonOutline"
                      onClick={() =>
                        this.setState(
                          { selected_page: { label: 1, value: 1 } },
                          () => this.filterUsers(this.state.selected_page.value)
                        )
                      }
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-outline-success pull-right"
                      onClick={() => showModalNoOutsideClick(UserManagementModal.ID)}
                    >
                      Add User
                    </button>
                  </div>
                </div>
              </main>
            </div>



         
            {this.state.tableResults && this.state.tableResults.length > 0 && (
              <div className="col-md-12 pt-2">
                <div className="card mt-2" style={{marginBottom: '4rem'}}>
                  <div className="card-header verlag-bold stickToTop" style={{zIndex: 2}}>
                    <h4 className="text-center text-md-left">
                      Users
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                          Total: {this.state.totalUsers}
                        </h4>
                        <h4 className="float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            aria-label="Table Page Number"
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.changePage(e.value)
                              )
                            }
                            className={"state_select page-num-select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button
                        className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="p-0 table-responsive">
                    <SimpleTable
                      columns={[
                        {
                          label: "Name",
                          key: "DisplayName",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#"}
                                className={'tableNameLinkColor'}
                                onClick={() => {
                                  if (
                                    val.Permissions.find((p) =>
                                      p.desc === 'Global Admin'
                                    ) && !UserManagement.UserContext.UserRoles.split(',').includes('Streamline Admin')
                                  ) {
                                    return sweetalert.fire({
                                      icon: "info",
                                      text:
                                        "Global Admins cannot be modified. Please contact " +
                                        "IMMY Technology Group to remove or add Global Admins.",
                                      title: "Attention",
                                    });
                                  }
                                  this.setState({ selectedUser: val }, () =>
                                    showModalNoOutsideClick(UserManagementModal.ID)
                                  );
                                }}
                              >
                                {val.DisplayName}
                              </a>
                            );
                          },
                          popoverText: "Click to sort by Last Name",
                        },
                        {
                          label: "Email",
                          key: "Email",
                          popoverText: "Click to sort by Email",
                        },
                        {
                          label: "Permissions",
                          key: "Permissions",
                          rawFormat: (val: { Permissions: { id; desc }[] }) => {
                            let perms: string[] = val.Permissions.map(
                              (v) => v.desc + ", "
                            );
                            let lastInd = perms.length - 1;
                            if (lastInd >= 0)
                              perms[lastInd] = perms[lastInd].slice(
                                0,
                                perms[lastInd].length - 2
                              ); // Remove last space
                            return <p>{perms}</p>;
                          },
                          popoverText: "Click to sort by Permissions",
                        },
                        {
                          label: "Facility Group",
                          key: "AuthFacilityGroup",
                          rawFormat: (val: {
                            FirstName;
                            LastName;
                            Email;
                            Status;
                            AuthFacilityGroup;
                            Permissions;
                            UUID
                            PhoneNumber
                          }) => {
                            let facility = this.state.customerGroups.find(
                              (af) => af.facilityGroupID === val.AuthFacilityGroup
                            );

                            return (
                              <p>
                                {facility && facility.label
                                  ? facility.label
                                  : "null"}
                              </p>
                            );
                          },
                          popoverText: "Click to sort by Facility Group",
                        },
                        {
                          label: "Status",
                          key: "Status",
                          popoverText: "Click to sort by Status",
                        },
                        {
                          label: "Action",
                          key: "action",
                          rawFormat: (val) => {
                            if (val.Status === 'Disabled') {
                              return (
                                <button
                                  type={"button"}
                                  className={"btn btn-outline-success"}
                                  onClick={() =>
                                    this.enableUserAccount(val)
                                  }
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Enable User Account"
                                >
                                  <FaUserPlus /> Enable Account
                                </button>
                              )
                            }
                            if (val.Status === 'Enabled' || val.Status === 'New') {
                              return (
                                <button
                                  type={"button"}
                                  className={"btn btn-outline-primary"}
                                  onClick={() =>
                                    this.resetPasswordEmail(val)
                                  }
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Reset Password"
                                >
                                  <BiMailSend /> Reset Password
                                </button>
                              )
                            }
                            return <div />;
                          },
                        },
                      ]}
                      table_data={this.state.tableResults}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}